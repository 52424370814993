import * as React from "react"
import { Store } from "../Store"
import { observer } from "mobx-react"
import { Icon, Item } from "semantic-ui-react"
import { TagsComponent } from "./TagsComponent"
import moment from 'moment'
import { PersonsComponent } from "./PersonsComponent"
import { CompaniesComponent } from "./CompaniesComponent"


@observer
export class Event extends React.Component<{ store: Store }, {}> {


    handleEdit = () => {
        const store = this.props.store

        console.log("handleEdit")
        store.editActiveEvent()
    }

    render() {
        const store = this.props.store

        const event = store.activeEvent
        return (
            <Item>
                <Item.Content>
                    <Item.Meta>{moment(event.date).format("YYYY-MM-DD")}</Item.Meta>
                    <Item.Header as='h3'>{event.heading}</Item.Header>
                    <Icon name="edit" onClick={() => this.handleEdit()} />
                    <Item.Header as='h4'>Info</Item.Header>
                    <Item.Description>
                        {event.info}
                    </Item.Description>

                    <Item.Header as='h4'>Personer</Item.Header>
                    <Item.Extra>
                        <PersonsComponent store={store} persons={event.persons.map((personId: number) => store.getPerson(personId))} />
                    </Item.Extra>

                    <Item.Header as='h4'>Taggar</Item.Header>
                    <Item.Extra>
                        <TagsComponent store={store} tags={event.tags.map((tagId: number) => store.getTag(tagId))} />
                    </Item.Extra>

                    <Item.Header as='h4'>Företag</Item.Header>
                    <Item.Extra>
                        <CompaniesComponent store={store} companies={(event.companies ? event.companies.map((companyId: number) => store.getCompany(companyId)): [])} />
                    </Item.Extra>


                </Item.Content>
            </Item>
        )
    }
}

