import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { Company, Person, Store, Tag } from "../Store"
import { observer } from "mobx-react"
import { Button, Form, TextArea } from "semantic-ui-react"
import Creatable from "react-select/lib/Creatable"
import { ActionMeta } from "react-select/lib/types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


type State = {
    selectedTags: any[],
    selectedPersons: any[],
    selectedCompanies: any[]
}

type Props = { store: Store }

@observer
export class EditEvent extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)
        this.state = {
            selectedTags: this.props.store.activeEvent.tags.map((tagId: number) => {
                return { value: tagId, label: this.props.store.getTag(tagId).name }
            }),
            selectedPersons: this.props.store.activeEvent.persons.map((personId: number) => {
                const person = props.store.getPerson(personId)
                return { value: personId, label: person.firstName + " " + person.lastName }
            }),
            selectedCompanies: (this.props.store.activeEvent.companies ? this.props.store.activeEvent.companies.map((companyId: number) => {
                const company = props.store.getCompany(companyId)
                return { value: companyId, label: company.name }
            }) : []),

        }
    }

    handleSave = () => {
        const store = this.props.store

        store.activeEvent.tags = []
        this.state.selectedTags.forEach((obj: any) => {
            if (obj.hasOwnProperty('__isNew__') && obj.__isNew__ === true) {
                store.activeEvent.tags.push(store.createNewTag(obj.label))
            } else {
                store.activeEvent.tags.push(parseInt(obj.value))
            }
        })

        store.activeEvent.companies = []
        this.state.selectedCompanies.forEach((obj: any) => {
            if (!store.activeEvent.companies) {
                store.activeEvent.companies = []
            }
            if (obj.hasOwnProperty('__isNew__') && obj.__isNew__ === true) {
                store.activeEvent.companies.push(store.createNewCompany(obj.label))

            } else {
                store.activeEvent.companies.push(parseInt(obj.value))
            }
        })

        store.activeEvent.persons = []
        this.state.selectedPersons.forEach((obj: any) => {
            if (obj.hasOwnProperty('__isNew__') && obj.__isNew__ === true) {
                const strs = obj.label.split(' ')
                const firstName = strs[0]
                let lastName = ""
                if (strs.length > 1) {
                    lastName = strs[1]
                }
                store.activeEvent.persons.push(store.createNewPerson(firstName, lastName))
            } else {
                store.activeEvent.persons.push(parseInt(obj.value))
            }
        })

        store.saveActiveEvent()
        this.setState({ selectedTags: [], selectedPersons: [], selectedCompanies: [] })
        store.activeEvent = store.getEmptyEvent()
    }

    handleTagsChange = (newValue: any, actionMeta: ActionMeta) => {
        this.setState({ selectedTags: newValue })
    }

    handleCompaniesChange = (newValue: any, actionMeta: ActionMeta) => {
        this.setState({ selectedCompanies: newValue })
    }

    handlePersonsChange = (newValue: any, actionMeta: ActionMeta) => {
        this.setState({ selectedPersons: newValue })
    }

    onDateChange = (date: Date) => {
        this.props.store.activeEvent.date = date.getTime()
    }

    render() {
        const store = this.props.store
        return (
            <div>
                <h4>{this.props.store.activeEvent.heading === "" ? "Skapa ny händelse" : "Editera händelse"}</h4>

                <Form onSubmit={this.handleSave}>

                    <Form.Field>
                        <label>Datum</label>
                        <DatePicker
                            selected={new Date(store.activeEvent.date)}
                            onChange={this.onDateChange}
                            locale="sv"
                            dateFormat="yyyy-MM-dd"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Heading</label>
                        <input placeholder='Heading'
                               value={store.activeEvent.heading}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   this.props.store.activeEvent.heading = event.target.value
                               }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Info</label>
                        <TextArea placeholder='Info' value={store.activeEvent.info}
                                  onChange={(event: FormEvent<HTMLTextAreaElement>) => {
                                      this.props.store.activeEvent.info = event.currentTarget.value
                                  }}
                        />

                    </Form.Field>

                    <Form.Field>
                        <label>Taggar</label>
                        <Creatable
                            onChange={this.handleTagsChange}
                            options={store.tagsArray.map((tag: Tag) => {
                                return { value: tag.id, label: tag.name }
                            })}
                            formatCreateLabel={() => `Skapa ny Tag`}
                            isMulti
                            value={this.state.selectedTags}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Personer</label>
                        <Creatable
                            onChange={this.handlePersonsChange}
                            options={store.personsArray.map((person: Person) => {
                                return { value: person.id, label: person.firstName + " " + person.lastName }
                            })}
                            formatCreateLabel={() => `Skapa ny Person`}
                            isMulti
                            value={this.state.selectedPersons}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Företag</label>
                        <Creatable
                            onChange={this.handleCompaniesChange}
                            options={store.companiesArray.map((company: Company) => {
                                return { value: company.id, label: company.name }
                            })}
                            formatCreateLabel={() => `Skapa nytt Företag`}
                            isMulti
                            value={this.state.selectedCompanies}
                        />
                    </Form.Field>

                    <Button type='submit' disabled={store.activeEvent.heading.length === 0}>Spara</Button>
                </Form>
            </div>
        )
    }
}

