import * as React from "react"
import { observer } from "mobx-react"
import { List } from "semantic-ui-react"
import { Store, Company } from "../Store"

@observer
export class CompaniesComponent extends React.Component<{ store: Store, companies: Company[] }, {}> {

    render() {
        const store = this.props.store

        const companies = this.props.companies
            .map((company: Company) =>
                <List.Item key={company.id} onClick={() => store.selectCompany(company.id)}>{company.name}</List.Item>
            )

        return (
            <div>

                <List>
                    {companies}
                </List>
            </div>
        )
    }
}


