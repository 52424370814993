import { Database } from "./Store"
import Auth from "./Auth"

export interface IRepository {
    fetchDatabase(): Promise<Database>

    save(database: Database): Promise<Response>
}

const devServer = "http://localhost:13457/"
const prodServer = "https://api.agaro.se/"

export class Repository implements IRepository {

    private server: string

    constructor() {
        // eslint-disable-next-line no-restricted-globals
        if (location.hostname === "localhost") {
            this.server = devServer
        } else {
            this.server = prodServer
        }

    }

    async fetchDatabase(): Promise<Database> {

        return fetch(`${this.server}api/getdb`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `bearer ${Auth.getToken()}`
            },
            credentials: "omit"
        })
            .then((response => {
                return response.json()
            }))
            .then((db: Database) => {
                return db
            })
    }

    async save(database: Database) {
        return fetch(`${this.server}api/savedb`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `bearer ${Auth.getToken()}`
            },
            credentials: "omit",
            body: JSON.stringify(database)
        })
    }
}
