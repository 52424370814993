import React from 'react'
import './App.css'
import { Container, Icon, Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { ActivePage, DatabaseLoadingStatus, Store } from "./Store"
import { LoginPage } from "./components/LoginPage"
import { observer } from "mobx-react"
import Auth from "./Auth"
import { Events } from "./components/Events"
import { Event } from "./components/Event"
import { Persons } from "./components/Persons"
import { Person } from "./components/Person"
import { Tag } from "./components/Tag"
import { EditPerson } from "./components/EditPerson"
import { EditTag } from "./components/EditTag"
import { EditEvent } from "./components/EditEvent"
import { Tags } from "./components/Tags"
import { Companies } from "./components/Companies"
import { Company } from "./components/Company"
import { EditCompany } from "./components/EditCompany"


type Props = { store: Store }

@observer
export class App extends React.Component<Props, {}> {

    onLogOutClick = () => {
        Auth.deauthenticateUser()
        this.props.store.isAuthenticated = false
    }

    render() {

        const store = this.props.store

        let page = <p>Not found</p>

        switch (store.activePage) {

            case ActivePage.events:
                page = <div><h3>Händelser</h3><Events store={this.props.store} events={store.eventsArray} /></div>
                break

            case ActivePage.persons:
                page =
                    <div><h3>Personer</h3><Persons store={store} /></div>
                break

            case ActivePage.tags:
                page = <div><h3>Taggar</h3><Tags store={store} /></div>
                break

            case ActivePage.companies:
                page = <div><h3>Företag</h3><Companies store={store} /></div>
                break

            case ActivePage.event:
                page = <Event store={store} />
                break

            case ActivePage.person:
                page = <Person store={store} />
                break

            case ActivePage.tag:
                page = <Tag store={store} />
                break

            case ActivePage.company:
                page = <Company store={store} />
                break

            case ActivePage.editPerson:
                page = <EditPerson store={store} />
                break

            case ActivePage.editTag:
                page = <EditTag store={store} />
                break

            case ActivePage.editEvent:
                page = <EditEvent store={store} />
                break

            case ActivePage.editCompany:
                page = <EditCompany store={store} />
                break
        }

        if (this.props.store.databaseLoadingStatus === DatabaseLoadingStatus.LOADING) {
            return <div>Loading...</div>
        }

        return (
            (this.props.store.isAuthenticated ?

                    <div>
                        <Menu fixed='top'>
                            <Menu.Item
                                name="Event"
                                onClick={() => store.setActivePage(ActivePage.events)}
                                active={store.activePage === ActivePage.events}
                            >
                                <Icon name="calendar alternate" />
                            </Menu.Item>

                            <Menu.Item
                                name="Personer"
                                onClick={() => store.setActivePage(ActivePage.persons)}
                                active={store.activePage === ActivePage.persons}
                            >
                                <Icon name="users" />
                            </Menu.Item>

                            <Menu.Item
                                name="Taggar"
                                onClick={() => store.setActivePage(ActivePage.tags)}
                                active={store.activePage === ActivePage.tags}
                            >
                                <Icon name="tags" />
                            </Menu.Item>

                            <Menu.Item
                                name="Företag"
                                onClick={() => store.setActivePage(ActivePage.companies)}
                                active={store.activePage === ActivePage.companies}
                            >
                                <Icon name="factory" />
                            </Menu.Item>

                            <Menu.Item
                                name="Nytt Event"
                                onClick={() => store.newEvent()}
                                active={store.activePage === ActivePage.editEvent}>

                                <Icon name="calendar plus" />
                            </Menu.Item>

                            <Menu.Item
                                name="Ny Person"
                                onClick={() => store.newPerson()}
                                active={store.activePage === ActivePage.editPerson}
                            ><Icon name="add user" />
                            </Menu.Item>

                            <Menu.Item
                                name="Ny Tag"
                                onClick={() => store.newTag()}
                                active={store.activePage === ActivePage.editTag}>
                                <Icon name="tag" />
                            </Menu.Item>

                            <Menu.Item
                                name="Nytt Företag"
                                onClick={() => store.newCompany()}
                                active={store.activePage === ActivePage.editCompany}>
                                <Icon name="factory" />
                            </Menu.Item>


                            <Menu.Item onClick={() => this.onLogOutClick()}>
                                <Icon name="log out" />
                            </Menu.Item>
                        </Menu>

                        <Container style={{ paddingTop: '4em' }}>
                            {page}
                        </Container>

                    </div>
                    :
                    <div><LoginPage store={this.props.store} /></div>
            )
        )
    }
}


