import * as React from "react"
import { ChangeEvent, createRef } from "react"
import { Store, Tag } from "../Store"
import { observer } from "mobx-react"
import { Input } from "semantic-ui-react"
import { TagsComponent } from "./TagsComponent"

@observer
export class Tags extends React.Component<{ store: Store }, {}> {

    private nameInput = createRef<Input>()

    componentDidMount(): void {
        const node = this.nameInput.current

        if (node) {
            node.focus()
        }
        this.props.store.tagsFilter = ""
    }


    render() {
        const store = this.props.store

        const tags = store.tagsArray
            .filter((tag: Tag) => {
                const filter = store.tagsFilter.toLocaleLowerCase()
                const name = tag.name.toLocaleLowerCase()

                return name.includes(filter)

            })

        return (
            <div>
                <Input placeholder="Sök..."
                       ref={this.nameInput}
                       value={this.props.store.tagsFilter}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => this.props.store.tagsFilter = event.target.value} />

                <TagsComponent store={store} tags={tags} />
            </div>
        )
    }
}


