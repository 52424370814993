import { action, observable } from "mobx"
import Auth from "./Auth"
import { getMaxId } from "./utils"
import moment from 'moment'
import { IRepository } from "./Repository"

const devServer = "http://localhost:13457/"
const prodServer = "https://api.agaro.se/"

export interface Id {
    id: number
}

export interface Person extends Id {
    firstName: string;
    lastName: string;
    info: string;
    tags: Array<number>;
    companies?: Array<number>
}

export interface Event extends Id {
    date: number;
    heading: string;
    info: string;
    tags: Array<number>;
    persons: Array<number>;
    companies?: Array<number>
}

export interface Tag extends Id {
    name: string;
    info: string;
    tags?: Array<number>;
}

export interface Company extends Id {
    name: string;
    info: string;
}

export interface Database {
    databaseversion: number
    personArray: Person[]
    eventArray: Event[]
    tagArray: Tag[]
    companyArray?: Company[]
}

export enum ActivePage {'events', 'persons', 'tags', 'companies', 'event', 'person', 'tag', 'company', 'editPerson', 'editTag', 'editEvent', 'editCompany'}

export enum DatabaseLoadingStatus {NOT_INITIATED = 0, LOADING, DONE}

export class Store {

    repository: IRepository

    constructor(repository: IRepository) {
        this.repository = repository

        this.isAuthenticated = Auth.isUserAuthenticated()

        // eslint-disable-next-line no-restricted-globals
        if (location.hostname === "localhost") {
            this.server = devServer
        } else {
            this.server = prodServer
        }

        if (this.isAuthenticated) {
            this.loadDatabase()
        }
    }

    @observable isAuthenticated: boolean
    @observable databaseLoadingStatus: DatabaseLoadingStatus = DatabaseLoadingStatus.NOT_INITIATED

    @observable server: string

    @observable personsArray: Array<Person> = []
    @observable eventsArray: Array<Event> = []
    @observable tagsArray: Array<Tag> = []
    @observable companiesArray: Array<Company> = []

    @observable personsFilter: string = ""
    @observable tagsFilter: string = ""
    @observable companiesFilter: string = ""

    @observable activePage: ActivePage = ActivePage.events

    @observable activePerson: Person = { id: -1, firstName: "", lastName: "", info: "", tags: [] }
    @observable activeTag: Tag = { id: -1, name: "", info: "" }
    @observable activeCompany: Company = { id: -1, name: "", info: "" }
    @observable activeEvent: Event = { id: -1, persons: [], tags: [], info: "", date: 0, heading: "" }

    nextPersonId: number = -1
    nextEventId: number = -1
    nextTagId: number = -1
    nextCompanyId: number = -1

    databaseVersion: number = -1

    @action whenLoggedIn() {
        this.isAuthenticated = true
        this.loadDatabase()
    }

    @action selectEvent(id: number) {
        this.activeEvent = this.getEvent(id)
        this.activePage = ActivePage.event
    }

    @action editEvent(id: number) {
        this.activeEvent = this.getEvent(id)
        this.editActiveEvent()
    }

    @action editActiveEvent() {
        this.activePage = ActivePage.editEvent
    }

    @action selectPerson(id: number) {
        this.activePerson = this.getPerson(id)
        this.activePage = ActivePage.person
    }

    @action editActivePerson() {
        this.activePage = ActivePage.editPerson
    }

    @action selectTag(id: number) {
        this.activeTag = this.getTag(id)
        this.setActivePage(ActivePage.tag)
    }

    @action editActiveTag() {
        this.activePage = ActivePage.editTag
    }

    @action selectCompany(id: number) {
        this.activeCompany = this.getCompany(id)
        this.setActivePage(ActivePage.company)
    }

    @action editActiveCompany() {
        this.activePage = ActivePage.editCompany
    }

    @action saveActiveEvent() {
        const copyEvent = JSON.parse(JSON.stringify(this.activeEvent))
        const id = this.eventsArray.findIndex((event: Event) => event.id === this.activeEvent.id)
        if (id > -1) {
            this.eventsArray[id] = copyEvent
        } else {
            this.eventsArray.push(copyEvent)
        }
        this.sortEventsArray()
        this.save()
    }

    @action saveActivePerson() {
        const copyPerson = JSON.parse(JSON.stringify(this.activePerson))
        const id = this.personsArray.findIndex((person: Person) => person.id === this.activePerson.id)
        if (id > -1) {
            this.personsArray[id] = copyPerson
        } else {
            this.personsArray.push(copyPerson)
        }
        this.sortPersonsArray()
        this.save()
    }

    @action saveActiveTag() {
        const copyTag = JSON.parse(JSON.stringify(this.activeTag))
        const id = this.tagsArray.findIndex((tag: Tag) => tag.id === this.activeTag.id)
        if (id > -1) {
            this.tagsArray[id] = copyTag
        } else {
            this.tagsArray.push(copyTag)
        }
        this.sortTagsArray()
        this.save()
    }

    @action saveActiveCompany() {
        const copyCompany = JSON.parse(JSON.stringify(this.activeCompany))
        const id = this.companiesArray.findIndex((company: Company) => company.id === this.activeCompany.id)
        if (id > -1) {
            this.companiesArray[id] = copyCompany
        } else {
            this.companiesArray.push(copyCompany)
        }
        this.sortCompaniesArray()
        this.save()
    }

    @action createNewTag = (name: string): number => {
        const id = this.nextTagId
        this.tagsArray.push({ id, name, info: "" })
        this.nextTagId++
        return id
    }

    @action createNewCompany = (name: string): number => {
        const id = this.nextCompanyId
        this.companiesArray.push({ id, name, info: "" })
        this.nextCompanyId++
        return id
    }

    @action createNewPerson = (firstName: string, lastName: string): number => {
        const id = this.nextPersonId
        this.personsArray.push({ id, firstName, lastName, info: "", tags: [] })
        this.nextPersonId++
        return id
    }

    @action newEvent() {
        this.activeEvent = this.getEmptyEvent()
        this.activePage = ActivePage.editEvent
    }

    @action newTag() {
        this.activeTag = this.getEmptyTag()
        this.activePage = ActivePage.editTag
    }

    @action newCompany() {
        this.activeCompany = this.getEmptyCompany()
        this.activePage = ActivePage.editCompany
    }

    @action newPerson() {
        this.activePerson = this.getEmptyPerson()
        this.activePage = ActivePage.editPerson
    }

    @action setActivePage(page: ActivePage) {
        this.activePage = page
    }

    loadDatabase() {

        this.databaseLoadingStatus = DatabaseLoadingStatus.LOADING

        this.repository.fetchDatabase()
            .then((data: Database) => {
                this.databaseVersion = data.databaseversion

                this.personsArray = data.personArray
                this.sortPersonsArray()
                this.nextPersonId = getMaxId(this.personsArray) + 1

                this.eventsArray = data.eventArray
                this.sortEventsArray()
                this.nextEventId = getMaxId(this.eventsArray) + 1

                this.tagsArray = data.tagArray
                this.sortTagsArray()
                this.nextTagId = getMaxId(this.tagsArray) + 1

                if (data.companyArray) {
                    this.companiesArray = data.companyArray
                    this.sortCompaniesArray()
                    this.nextCompanyId = getMaxId(this.companiesArray) + 1
                } else {
                    this.nextCompanyId = 1
                }

                this.databaseLoadingStatus = DatabaseLoadingStatus.DONE
            })

            .catch((err) => {
                console.log(err)
                this.databaseLoadingStatus = DatabaseLoadingStatus.NOT_INITIATED
                this.isAuthenticated = false
            })
    }

    save() {
        const personArray = this.personsArray

        const eventArray = this.eventsArray

        const tagArray = this.tagsArray

        this.repository.save({
            "databaseversion": this.databaseVersion, personArray, eventArray, tagArray,
            companyArray: this.companiesArray
        })
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error))
    }

    getEventsForPerson = (personId: number): Event[] => {
        return this.eventsArray.filter((event: Event) =>
            event.persons.indexOf(personId) > -1
        )
    }

    getEventsForTag = (tagId: number): Event[] => {
        return this.eventsArray.filter((event: Event) =>
            event.tags.indexOf(tagId) > -1
        )
    }

    getEventsForCompany = (companyId: number): Event[] => {
        return this.eventsArray.filter((event: Event) => {
                if (event.companies) {
                    return event.companies.indexOf(companyId) > -1
                } else {
                    return false
                }
            }
        )
    }

    getEvent(id: number) {
        for (let i in this.eventsArray) {
            if (id === this.eventsArray[parseInt(i)].id) {
                return this.eventsArray[parseInt(i)]
            }
        }
        console.log("Could not find event with id ", id)
        return {
            id: -1,
            heading: "Not found",
            persons: [],
            tags: [],
            info: "",
            date: 0
        }
    }

    getPerson(id: number): Person {
        for (let i in this.personsArray) {
            if (id === this.personsArray[parseInt(i)].id) {
                return this.personsArray[parseInt(i)]
            }
        }
        console.log("Could not find person with id ", id)
        return {
            id: -1,
            firstName: "Not",
            lastName: "Found",
            info: "",
            tags: []
        }
    }

    getPersonsForTag = (tagId: number): Person[] => {
        return this.personsArray.filter((person: Person) =>
            person.tags.indexOf(tagId) > -1
        )
    }

    getPersonsForCompany = (companyId: number): Person[] => {
        return this.personsArray.filter((person: Person) => {
                if (person.companies) {
                    return person.companies.indexOf(companyId) > -1
                } else {
                    return false
                }
            }
        )
    }

    getEmptyPerson(): Person {
        return {
            id: this.nextPersonId++,
            firstName: "",
            lastName: "",
            info: "",
            tags: []
        }
    }

    getEmptyTag(): Tag {
        return {
            id: this.nextTagId++,
            name: "",
            info: ""
        }
    }

    getEmptyCompany(): Company {
        return {
            id: this.nextCompanyId++,
            name: "",
            info: ""
        }
    }

    getEmptyEvent(): Event {
        return {
            id: this.nextEventId++,
            heading: "",
            date: moment().valueOf(),
            persons: [],
            tags: [],
            info: ""
        }
    }

    getTag = (id: number): Tag => {
        const ix = this.tagsArray.findIndex((tag: Tag) => tag.id === id)

        if (ix > -1) {
            return this.tagsArray[ix]
        }

        console.log("Could not find tag with id ", id)
        console.trace()
        return {
            id: -1,
            name: "Not Found",
            info: "",
            tags: []
        }
    }

    getCompany = (id: number): Tag => {
        const ix = this.companiesArray.findIndex((company: Company) => company.id === id)

        if (ix > -1) {
            return this.companiesArray[ix]
        }

        console.log("Could not find company with id ", id)
        console.trace()
        return {
            id: -1,
            name: "Not Found",
            info: ""
        }
    }

    sortPersonsArray = () => {
        this.personsArray = this.personsArray.slice().sort(function (a: Person, b: Person) {
            if (a.lastName.toLocaleUpperCase() < b.lastName.toLocaleUpperCase()) {
                return -1
            }
            if (a.lastName.toLocaleUpperCase() > b.lastName.toLocaleUpperCase()) {
                return 1
            }
            return 0
        })
    }

    sortEventsArray = () => {
        this.eventsArray = this.eventsArray.slice().sort(function (a: Event, b: Event) {
            if (a.date < b.date) {
                return 1
            }
            if (a.date > b.date) {
                return -1
            }
            return 0
        })
    }

    sortTagsArray = () => {
        this.tagsArray = this.tagsArray.slice().sort(function (a: Tag, b: Tag) {
            if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
                return 1
            }
            if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
                return -1
            }
            return 0
        })
    }

    sortCompaniesArray = () => {
        this.companiesArray = this.companiesArray.slice().sort(function (a: Company, b: Company) {
            if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
                return 1
            }
            if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
                return -1
            }
            return 0
        })
    }

    moveTagToCompany = (tagId: number) => {
        const tagIx = this.tagsArray.findIndex((tag: Tag) => tag.id === tagId)
        if (tagIx > -1) {
            const tag = this.tagsArray[tagIx]

            this.companiesArray.push({ id: tag.id, name: tag.name, info: tag.info })

            this.eventsArray.forEach((event: Event) => {
                const eventTagIx = event.tags.findIndex((id: number) => id === tagId)
                if (eventTagIx > -1) {
                    if (!event.companies) {
                        event.companies = []
                    }
                    event.companies.push(tagId)
                    event.tags.splice(eventTagIx, 1)
                }
            })

            this.personsArray.forEach((person: Person) => {
                const personTagIx = person.tags.findIndex((id: number) => id === tagId)
                if (personTagIx > -1) {
                    if (!person.companies) {
                        person.companies = []
                    }
                    person.companies.push(tagId)
                    person.tags.splice(personTagIx, 1)
                }
            })

            this.tagsArray.splice(tagIx, 1)

            this.save()
        } else {
            console.error("Cannot find tag id in tagsArray ", tagId)
        }
    }

    deleteTag = (tagId: number) => {
        const tagIx = this.tagsArray.findIndex((tag: Tag) => tag.id === tagId)
        if (tagIx > -1) {
            this.tagsArray.splice(tagIx, 1)

            this.save()
        } else {
            console.error("Cannot find tag id in tagsArray ", tagId)
        }
    }

    deletePerson = (personId: number) => {
        const presonIx = this.personsArray.findIndex((person: Person) => person.id === personId)
        if (presonIx > -1) {
            this.personsArray.splice(presonIx, 1)

            this.save()
        } else {
            console.error("Cannot find person id in personsArray ", personId)
        }
    }

}
