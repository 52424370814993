import * as React from "react"
import { Store } from "../Store"
import { observer } from "mobx-react"
import { Icon, Item } from "semantic-ui-react"
import { Events } from "./Events"
import { PersonsComponent } from "./PersonsComponent"


@observer
export class Company extends React.Component<{ store: Store }, {}> {

    render() {
        const store = this.props.store
        const company = store.activeCompany
        return (
            <Item>
                <Item.Content>
                    <Item.Header as='h3'>{company.name}</Item.Header>
                    <Icon name="edit" onClick={() => this.props.store.editActiveCompany()} />

                    <Item.Description>
                        <p>{company.info}</p>
                    </Item.Description>

                    <Item.Header as='h4'>Personer</Item.Header>

                    <Item.Extra>
                        <PersonsComponent store={store} persons={store.getPersonsForCompany(company.id)} />
                    </Item.Extra>

                    <Item.Header as='h4'>Händelser</Item.Header>

                    <Item.Extra>
                        <Events store={store} events={store.getEventsForCompany(company.id)} />
                    </Item.Extra>
                </Item.Content>
            </Item>
        )
    }
}

