import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { Store } from "../Store"
import { observer } from "mobx-react"
import { Button, Form, TextArea } from "semantic-ui-react"


type Props = { store: Store }

@observer
export class EditCompany extends React.Component<Props, {}> {


    handleSave = () => {
        const store = this.props.store

        store.saveActiveCompany()
        store.activeCompany = store.getEmptyCompany()
    }

    render() {
        const store = this.props.store
        return (<div>
                <h4>{this.props.store.activeCompany.name === "" ? "Skapa nytt företag" : "Editera företag"}</h4>
                <Form onSubmit={this.handleSave}>

                    <Form.Field>
                        <label>Namn</label>
                        <input placeholder='Namn'
                               value={store.activeCompany.name}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   this.props.store.activeCompany.name = event.target.value
                               }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Info</label>
                        <TextArea placeholder='Info' value={store.activeCompany.info}
                                  onChange={(event: FormEvent<HTMLTextAreaElement>) => {
                                      this.props.store.activeCompany.info = event.currentTarget.value
                                  }}
                        />
                    </Form.Field>

                    <Button type='submit' disabled={store.activeCompany.name.length === 0}>Spara</Button>
                </Form>
            </div>
        )
    }
}

