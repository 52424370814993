import * as React from "react"
import { ChangeEvent, createRef } from "react"
import { Person, Store } from "../Store"
import { observer } from "mobx-react"
import { Input } from "semantic-ui-react"
import { PersonsComponent } from "./PersonsComponent"


@observer
export class Persons extends React.Component<{ store: Store }, {}> {

    private nameInput = createRef<Input>()

    componentDidMount(): void {
        const node = this.nameInput.current

        if (node) {
            node.focus()
        }
        this.props.store.personsFilter = ""
    }

    render() {
        const store = this.props.store

        const persons = store.personsArray
            .filter((person: Person) => {
                const filter = store.personsFilter.toLocaleLowerCase()
                const firstName = person.firstName.toLocaleLowerCase()
                const lastName = person.lastName.toLocaleLowerCase()

                return firstName.includes(filter) ||
                    lastName.includes(filter) ||
                    `${firstName} ${lastName}`.includes(filter)

            })

        return (
            <div>
                <Input placeholder="Sök..."
                       ref={this.nameInput}
                       value={this.props.store.personsFilter}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => this.props.store.personsFilter = event.target.value} />

                <PersonsComponent store={store} persons={persons} />
            </div>
        )
    }
}

