import * as React from "react"
import { Event, Store } from "../Store"
import { Header, Icon, Label, Table } from "semantic-ui-react"
import { observer } from "mobx-react"
import moment from 'moment'

@observer
export class Events extends React.Component<{ store: Store, events: Event[] }, {}> {

    render = () => {

        const rows = this.props.events.map((event: Event) => this.renderTableRow(event))

        return (
            <Table basic='very' celled collapsing>
                {/*<Table.Header>*/}
                {/*    <Table.Row>*/}
                {/*        <Table.HeaderCell>Datum</Table.HeaderCell>*/}
                {/*        <Table.HeaderCell>Händelse</Table.HeaderCell>*/}
                {/*        <Table.HeaderCell>Taggar</Table.HeaderCell>*/}
                {/*        <Table.HeaderCell>Personer</Table.HeaderCell>*/}
                {/*        <Table.HeaderCell>Ändra</Table.HeaderCell>*/}
                {/*    </Table.Row>*/}
                {/*</Table.Header>*/}

                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        )
    }

    renderTableRow = (event: Event) => {

        const tags = event.tags.map((tagId: number) => {
            const tag = this.props.store.getTag(tagId)
            if (tag) {
                return <Label key={tag.id} onClick={() => this.props.store.selectTag(tag.id)}>{tag.name}</Label>
            } else return ""
        })

        const companies = (event.companies ? event.companies.map((companyId: number) => {
            const company = this.props.store.getCompany(companyId)
            if (company) {
                return <Label key={company.id} onClick={() => this.props.store.selectCompany(company.id)}>{company.name}</Label>
            } else return ""
        }) : [])

        const persons = event.persons.map((personId: number) => {
            const person = this.props.store.getPerson(personId)
            if (person) {
                return <Label key={person.id} onClick={() => this.props.store.selectPerson(person.id)}>{person.firstName} {person.lastName}</Label>
            } else return ""
        })

        return (
            <Table.Row key={event.id}>
                <Table.Cell onClick={() => this.props.store.selectEvent(event.id)}>
                    {moment(event.date).format('YYYY-MM-DD')}
                </Table.Cell>
                <Table.Cell onClick={() => this.props.store.selectEvent(event.id)}>

                    <Header as='h4' image>
                        <Header.Content>
                            {event.heading}
                            <Header.Subheader>{event.info}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell>{tags}</Table.Cell>
                <Table.Cell>{persons}</Table.Cell>
                <Table.Cell>{companies}</Table.Cell>
                <Table.Cell><Icon name="edit outline" onClick={() => this.props.store.editEvent(event.id)} /></Table.Cell>
            </Table.Row>
        )
    }
}
