import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {Store} from './Store'
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import { Repository } from "./Repository"

registerLocale('sv', sv)
setDefaultLocale("sv")

const repo = new Repository()
const store = new Store(repo)

ReactDOM.render(<App store={store}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
