import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { Company, Store, Tag } from "../Store"
import { observer } from "mobx-react"
import { Button, Form, TextArea } from "semantic-ui-react"
import Creatable from "react-select/lib/Creatable"
import { ActionMeta } from "react-select/lib/types"

type State = {
    selectedTags: any[]
    selectedCompanies: any[]
}

type Props = { store: Store }

@observer
export class EditPerson extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)
        this.state = {
            selectedTags: this.props.store.activePerson.tags.map((tagId: number) => {
                return { value: tagId, label: this.props.store.getTag(tagId).name }
            }),
            selectedCompanies: (this.props.store.activePerson.companies ? this.props.store.activePerson.companies.map((companyId: number) => {
                return { value: companyId, label: this.props.store.getCompany(companyId).name }
            }) : [])
        }
    }

    handleSave = () => {
        const store = this.props.store

        store.activePerson.tags = []
        this.state.selectedTags.forEach((obj: any) => {
            if (obj.hasOwnProperty('__isNew__') && obj.__isNew__ === true) {
                store.activePerson.tags.push(store.createNewTag(obj.label))
            } else {
                store.activePerson.tags.push(parseInt(obj.value))
            }
        })

        store.activePerson.companies = []
        this.state.selectedCompanies.forEach((obj: any) => {
            if (!store.activePerson.companies) {
                store.activePerson.companies = []
            }
            if (obj.hasOwnProperty('__isNew__') && obj.__isNew__ === true) {
                store.activePerson.companies.push(store.createNewCompany(obj.label))

            } else {
                store.activePerson.companies.push(parseInt(obj.value))
            }
        })

        store.saveActivePerson()
        this.setState({ selectedTags: [], selectedCompanies: [] })
        store.activePerson = store.getEmptyPerson()
    }

    handleTagsChange = (newValue: any, actionMeta: ActionMeta) => {
        this.setState({ selectedTags: newValue })
    }

    handleCompaniesChange = (newValue: any, actionMeta: ActionMeta) => {
        this.setState({ selectedCompanies: newValue })
    }

    render() {
        const store = this.props.store
        return (<div>
                <h4>{this.props.store.activePerson.lastName === "" ? "Skapa ny person" : "Editera person"}</h4>

                <Form onSubmit={this.handleSave}>

                    <Form.Field>
                        <label>Förnamn</label>
                        <input placeholder='Förnamn'
                               value={store.activePerson.firstName}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   this.props.store.activePerson.firstName = event.target.value
                               }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Efternamn</label>
                        <input placeholder='Efternamn'
                               value={store.activePerson.lastName}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   this.props.store.activePerson.lastName = event.target.value
                               }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Info</label>
                        <TextArea placeholder='Info' value={store.activePerson.info}
                                  onChange={(event: FormEvent<HTMLTextAreaElement>) => {
                                      this.props.store.activePerson.info = event.currentTarget.value
                                  }}
                        />

                    </Form.Field>

                    <Form.Field>
                        <label>Taggar</label>
                        <Creatable
                            onChange={this.handleTagsChange}
                            options={store.tagsArray.map((tag: Tag) => {
                                return { value: tag.id, label: tag.name }
                            })}
                            formatCreateLabel={() => `Skapa ny Tag`}
                            isMulti
                            value={this.state.selectedTags}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Företag</label>
                        <Creatable
                            onChange={this.handleCompaniesChange}
                            options={store.companiesArray.map((company: Company) => {
                                return { value: company.id, label: company.name }
                            })}
                            formatCreateLabel={() => `Skapa nytt Företag`}
                            isMulti
                            value={this.state.selectedCompanies}
                        />
                    </Form.Field>

                    <Button type='submit' disabled={store.activePerson.firstName.length === 0 || store.activePerson.lastName.length === 0}>Spara</Button>
                </Form>
            </div>
        )
    }
}

