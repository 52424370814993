import * as React from "react"
import { ChangeEvent, createRef } from "react"
import { Store, Company } from "../Store"
import { observer } from "mobx-react"
import { Input } from "semantic-ui-react"
import { TagsComponent } from "./TagsComponent"
import { CompaniesComponent } from "./CompaniesComponent"

@observer
export class Companies extends React.Component<{ store: Store }, {}> {

    private nameInput = createRef<Input>()

    componentDidMount(): void {
        const node = this.nameInput.current

        if (node) {
            node.focus()
        }
        this.props.store.companiesFilter = ""
    }


    render() {
        const store = this.props.store

        const companies = store.companiesArray
            .filter((company: Company) => {
                const filter = store.companiesFilter.toLocaleLowerCase()
                const name = company.name.toLocaleLowerCase()

                return name.includes(filter)

            })

        return (
            <div>
                <Input placeholder="Sök..."
                       ref={this.nameInput}
                       value={this.props.store.companiesFilter}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => this.props.store.companiesFilter = event.target.value} />

                <CompaniesComponent store={store} companies={companies} />
            </div>
        )
    }
}


