import * as React from "react"
import { observer } from "mobx-react"
import { List } from "semantic-ui-react"
import { Store, Tag } from "../Store"

@observer
export class TagsComponent extends React.Component<{ store: Store, tags: Tag[] }, {}> {

    render() {
        const store = this.props.store

        const tags = this.props.tags
            .map((tag: Tag) =>
                <List.Item key={tag.id} onClick={() => store.selectTag(tag.id)}>
                    <List.Content>
                        {/*<List.Header>*/}
                            {tag.name}
                        {/*</List.Header>*/}
                        {/*<List.Description>*/}
                        {/*    {tag.info}*/}
                        {/*</List.Description>*/}
                    </List.Content>
                </List.Item>
            )

        return (

            <List>
                {tags}
            </List>
        )

    }
}


/*





        const tags = this.props.tags
            .map((tag: Tag) =>
                <Table.Row key={tag.id} onClick={() => store.selectTag(tag.id)}>
                    <Table.Cell>
                        {tag.name}
                    </Table.Cell>
                    <Table.Cell>
                        {tag.info}
                    </Table.Cell>
                </Table.Row>
            )

        return (

            <Table basic='very'  collapsing>
                {tags}
            </Table>
        )
    }

 */

