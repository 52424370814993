import * as React from "react"
import { Person, Store } from "../Store"
import { observer } from "mobx-react"
import { List } from "semantic-ui-react"


@observer
export class PersonsComponent extends React.Component<{ store: Store, persons: Person[] }, {}> {

    render() {
        const store = this.props.store

        const persons = this.props.persons
            .map((person: Person) => {
                return <List.Item key={person.id} onClick={() => store.selectPerson(person.id)}>{person.firstName} {person.lastName}</List.Item>
            })

        return (
            <List>
                {persons}
            </List>
        )
    }
}

