import * as React from "react"
import { SyntheticEvent } from "react"
import { ActivePage, Store } from "../Store"
import { observer } from "mobx-react"
import { Button, Icon, Item } from "semantic-ui-react"
import { Events } from "./Events"
import { PersonsComponent } from "./PersonsComponent"


@observer
export class Tag extends React.Component<{ store: Store }, {}> {

    render() {
        const store = this.props.store
        const tag = store.activeTag
        const eventsForTag = store.getEventsForTag(tag.id)
        const personsForTag = store.getPersonsForTag(tag.id)
        return (
            <Item>
                <Item.Content>
                    <Item.Header as='h3'>{tag.name}</Item.Header>
                    <Icon name="edit" onClick={() => this.props.store.editActiveTag()} />

                    <Item.Description>
                        <p>{tag.info}</p>
                    </Item.Description>

                    <Item.Header as='h4'>Personer</Item.Header>

                    <Item.Extra>
                        <PersonsComponent store={store} persons={personsForTag} />
                    </Item.Extra>

                    <Item.Header as='h4'>Händelser</Item.Header>

                    <Item.Extra>
                        <Events store={store} events={eventsForTag} />
                    </Item.Extra>

                    <Button size="mini" compact basic onClick={(event: SyntheticEvent) => {
                        if (window.confirm("Vill du verkligen göra om taggen till ett företag?")) {
                            store.moveTagToCompany(tag.id)
                            event.stopPropagation()
                            store.setActivePage(ActivePage.tags)
                        }

                    }}>Flytta till Företag</Button>

                    <Button size="mini" compact basic color='red'
                            onClick={(event: SyntheticEvent) => {
                                if (window.confirm("Vill du verkligen ta bort taggen?")) {
                                    store.deleteTag(tag.id)
                                    event.stopPropagation()
                                    store.setActivePage(ActivePage.tags)
                                }

                            }}
                            disabled={eventsForTag.length > 0 || personsForTag.length > 0}
                    >Tabort</Button>

                </Item.Content>
            </Item>
        )
    }
}

