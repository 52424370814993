import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { Store } from "../Store"
import { observer } from "mobx-react"
import { Button, Form, TextArea } from "semantic-ui-react"


type Props = { store: Store }

@observer
export class EditTag extends React.Component<Props, {}> {


    handleSave = () => {
        const store = this.props.store

        store.saveActiveTag()
        store.activeTag = store.getEmptyTag()
    }

    render() {
        const store = this.props.store
        return (
            <div>
                <h4>{this.props.store.activeTag.name === "" ? "Skapa ny tag" : "Editera tag"}</h4>

                <Form onSubmit={this.handleSave}>

                    <Form.Field>
                        <label>Namn</label>
                        <input placeholder='Namn'
                               value={store.activeTag.name}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   this.props.store.activeTag.name = event.target.value
                               }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Info</label>
                        <TextArea placeholder='Info' value={store.activeTag.info}
                                  onChange={(event: FormEvent<HTMLTextAreaElement>) => {
                                      this.props.store.activeTag.info = event.currentTarget.value
                                  }}
                        />
                    </Form.Field>

                    <Button type='submit' disabled={store.activeTag.name.length === 0}>Spara</Button>
                </Form>
            </div>
        )
    }
}

