import * as React from "react";
import Auth from "../Auth";
import { Store } from "../Store"


interface State {
    email: string;
    password: string;
    emailSignup: string;
    passwordSignup: string;
    token: string;
    debug: string;
    message: string;
}

type Props = {store: Store}

export class LoginPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            email: "", password: "", token: "", debug: "starting", message: "",
            emailSignup: "", passwordSignup: ""
        };
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const component = this;

        const url = this.props.store.server + "login/";

        let xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                console.log("xmlhttp.responseText", xmlhttp.responseText);
                const t = JSON.parse(xmlhttp.responseText).token;
                Auth.authenticateUser(t);
                component.props.store.whenLoggedIn();
                component.setState({
                    email: component.state.email,
                    password: component.state.password,
                    token: component.state.token, debug: t,
                    message: ""
                });
                // hashHistory.push("/");
            }
            else if (xmlhttp.readyState === 4) {
                console.log(xmlhttp.responseText);
                component.setState({
                        email: component.state.email,
                        password: component.state.password,
                        token: component.state.token,
                        debug: component.state.debug,
                        message: xmlhttp.responseText
                    }
                );
            }
        };
        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader("Accept", "application/json");
        xmlhttp.setRequestHeader("Content-type", "application/json");
        xmlhttp.withCredentials = false;

        xmlhttp.send(JSON.stringify({"email": this.state.email, "password": this.state.password}));
    };

    handleEmailChange(event: any) {
        this.setState({
            email: event.target.value, password: this.state.password,
            token: this.state.token, debug: event.target.value,
            message: this.state.message
        });
    }

    handlePasswordChange(event: any) {
        this.setState({
            email: this.state.email, password: event.target.value,
            token: this.state.token, debug: event.target.value,
            message: this.state.message
        });
    }

    handleEmailSignupChange(event: any) {
        this.setState({
            emailSignup: event.target.value, debug: event.target.value,
        });
    }

    handlePasswordSignupChange(event: any) {
        this.setState({
            passwordSignup: event.target.value,
            debug: event.target.value
        });
    }

    render() {
        return (<div>
                <h3>Logga in </h3>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <input type="text" value={this.state.email} placeholder="Email"
                           onChange={this.handleEmailChange.bind(this)}/>
                    <input value={this.state.password} type="password" placeholder="Lösenord"
                           onChange={this.handlePasswordChange.bind(this)}/>
                    <input type="submit" value="Logga in"/>
                </form>
            </div>
        );
    }
}
