import * as React from "react"
import { ActivePage, Store } from "../Store"
import { observer } from "mobx-react"
import { Button, Icon, Item } from "semantic-ui-react"
import { TagsComponent } from "./TagsComponent"
import { Events } from "./Events"
import { CompaniesComponent } from "./CompaniesComponent"
import { SyntheticEvent } from "react"


@observer
export class Person extends React.Component<{ store: Store }, {}> {

    handleEdit = () => {
        const store = this.props.store

        store.editActivePerson()
    }
    render() {
        const store = this.props.store
        const person = store.activePerson
        const companies = person.companies ? person.companies.map((companyId: number) => store.getCompany(companyId)) : []
        const eventsForPerson = store.getEventsForPerson(person.id)
        return (
            <Item>
                <Item.Content>
                    <Item.Header as='h3'>{person.firstName} {person.lastName}</Item.Header>
                    <Icon name="edit" onClick={() => this.handleEdit()}/>

                    <Item.Header as='h4'>Info</Item.Header>
                    <Item.Description>
                        {person.info}
                    </Item.Description>
                    <Item.Header as='h4'>Tags</Item.Header>

                    <Item.Extra>
                        <TagsComponent store={store} tags={person.tags.map((tagId: number) => store.getTag(tagId))}/>
                    </Item.Extra>

                    <Item.Header as='h4'>Företag</Item.Header>
                    <Item.Extra>
                        <CompaniesComponent store={store} companies={companies}/>
                    </Item.Extra>

                    <Item.Header as='h4'>Event</Item.Header>

                    <Item.Extra>
                        <Events store={store} events={eventsForPerson} />
                    </Item.Extra>

                    <Button size="mini" compact basic color='red'
                            onClick={(event: SyntheticEvent) => {
                                if (window.confirm("Vill du verkligen ta bort personen?")) {
                                    store.deletePerson(person.id)
                                    event.stopPropagation()
                                    store.setActivePage(ActivePage.persons)
                                }

                            }}
                            disabled={person.tags.length > 0 || companies.length > 0 || eventsForPerson.length > 0}
                    >Tabort</Button>


                </Item.Content>
            </Item>
        )
    }
}

