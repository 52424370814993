import { Id } from "./Store"


export const getMaxId = (array: Id[]): number => {
    let max = 0
    array.forEach((element: Id) => {
        if (element.id > max) {
            max = element.id
        }
    })
    return max
}

